.PNF-container{
    display: flex;
    flex-direction: column;

    justify-content: center;
}
.PNF-background{
    width: 100%;
    height: 100%;
}
.PNF-title-container{
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.PNF-title{
    color: #346661;
    text-align: center;
    
    text-shadow: 0px 3px 5px #d18d8d;

}
.PNF-button{
    background: linear-gradient(
        90deg,
        #9ee3dd 0%,
        #ffdada 30%,
        #ffe0e0 70%
      );
        box-shadow: 1px 1px 5px#3C3C3C;
        border-radius: 20px;
        border: none;
        /* padding: 5px; */
}