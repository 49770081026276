
/* -----------------------Header------------------------- */
.plasmid-container{
    size: cover;
    /* height: 300px; */
    height: 20vw;
  }
  @media (prefers-reduced-motion: no-preference) {
    .plasmid-container{
      animation: plasmid-vector-spin infinite 5s linear;
    }
  }
  
@keyframes plasmid-vector-spin {
    from {
      /* transform: translate(0px); */
      transform: rotate(0deg);
    }
    to {
      /* transform: translate(50px); */
      transform: rotate(360deg);
    }
}
header{
    position: fixed;
    top: 0px;

    z-index: +3;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-right: 2rem;

    height: 100px;
    width: 100vw;

    background-color: #FFF9F9;
    box-shadow: 1px 1px #d1d1d1;

    margin: -8px;

}

#headlogo{
    margin-left: 40px;
}
#headlogoimage{
    width: 5vw;
}
#headlogoimage-phone{
    width: 10vw;
}
#headlogoimage-tiny{
    width: 30vw;
}
.source{
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    height: 20px;
    width: 40vw;

    overflow: hidden;

   
    margin-left: 40px;
}

a.asource{
    text-decoration: none;

    font-size: 16px;
    text-align: center;
    color: #295E59;

    margin-left: 15px;

}

ul.head-left {
    display: flex;
    justify-content: flex-start;
  }


.search-bar{
    flex-grow: 1;
    width: 90%;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 20px;
    margin-right: 30px;

}

.search-result{
    position: fixed;
    padding: 10px;
    /* width: 12%; */

    border-radius: 15px;
    box-shadow: 0px 2px 5px #d1d1d1;
    height: 100px;
    background-color: #FFF9F9;
    /* border: 2px solid #a8d5d0; */
    display: flex;
    flex-direction: column;

    overflow: auto;
    overflow-y: scroll;
}
.search-result::-webkit-scrollbar{
    display:none;
}

a.asearch-result-item{
    font-size: 14px;
    display: block;
    margin: 5px;
    color: #888888;
}

a.asearch-result-item:hover{
   
    color: #3C3C3C;
}


input#search{
    height: 50px;
    width: 100%;


    border: 2px solid #4DAFA6;
    border-radius: 10px;
    background: #FFF9F9;
    box-shadow: inset 2px 2px 5px #d1d1d1;

    margin: 5px;
    padding: 5px;
}
input#search:hover{
    transition-duration: 400ms;
    padding: 10px;
    box-shadow: 2px 2px 8px #a6a6a6;

}

input#search-submit{
    height: 50px;
    width: 50px;

    background-color: #4DAFA6;
    border-radius: 10px;
    border: 1px solid #4DAFA6;
    box-shadow: 2px 2px 5px #d1d1d1;


    font-size: 18px;
    color: #FFF9F9;
    text-align: center;

    padding: 5px;
}
#search-submit:hover{
    transition-duration: 300ms;
    background-color: #288b81;
}



/* -----------------------Main-container------------------------- */

.main-container{

    z-index: 0;
    display: flex;

    min-height: calc(100vh - 100px);

    margin-top: 100px;
}
/* -----------------------Sidebar------------------------- */

div.sidebar{
   
    /* overflow: scroll; */

    display: flex;
    flex-direction: column;

    width: 200px;

    background-color: #F3CECE;
    border-radius: 10px;

    margin: -8px;
    padding: 10px;
    
}
h2.sidebar-title{
    background-color: #FFF9F9;
    box-shadow: -1px -1px 5px #d1d1d1;

    padding: 5px;

    border: 1px solid #d1d1d1;
    border-radius: 20px;
}

a.past-release{
    display: block;

    color: #295E59;
    border-bottom: 1px solid #ED7575;

    margin: 5px;
    font-size: 14px;
}

a#return-top{
    text-align: center;
    font-size: 18px;

    color: #FFF9F9;
    background-color: #4DAFA6;
    border-radius: 10px;

    padding: 5px;
    margin: 10px;

}
.sidebar-inside{
    display: flex;
    flex-direction: column;


    margin-top: 0px;
}
.sidebar-inside.button{

   align-items: center;
   justify-content: center;

}
.site-news{
    /* background-color: #FFF7F7; */
    background: linear-gradient(
    90deg,
    #9ee3dd 0%,
    #ffdada 30%,
    #ffe0e0 70%
  );
    box-shadow: 1px 1px 5px#3C3C3C;
    border-radius: 10px;
    padding: 5px;
}
.site-news:hover{
    transition-duration: 300ms;
    background-color: #efdbdb;
    cursor: pointer;
}

.site-news-text{
    font-size: 14px;
    line-height: 20px;
}
.navi-button{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    color: #295E59;
    border: none;
    background: linear-gradient(
        90deg,
        #9ee3dd 0%,
        #ffdada 30%,
        #ffe0e0 70%
      );
    box-shadow: 1px 1px 5px#3C3C3C;
    padding: 10px;
    margin: 5px;
    border-radius: 20px;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
.opened-news-bg{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(
        90deg,
        #9ee3dd 0%,
        #ffdada 30%,
        #ffe0e0 70%
      );
    width: 100vw;
    height: 100vh;
    z-index: 5;
}
.news-detail{
    background: linear-gradient(
        90deg,
        #9ee3dd 0%,
        #ffdada 30%,
        #ffe0e0 70%
      );
        box-shadow: 1px 1px 5px#3C3C3C;
        border-radius: 20px;

        padding: 10px;
        width: 30vw;
}
.news-detail-head{
    display: flex;
    align-items: center;
    gap: 100px;
}
.news-detail-head-vert{
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    font-size: 12px;
    gap: 20px;
}
/* -----------------------main-view------------------------- */

.main-view{
    
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    width: calc(100vw - 200px);

    padding: 20px;
}
/* -----------------------main-content------------------------- */
.main-content{
    display: flex;
    flex-direction: column;
    
    margin: 10px;
    padding: 10px;
}
.phone-message{
    margin: 10px;
    padding: 5px;
    width: 200px;
    background: linear-gradient(
        90deg,
        #9ee3dd 0%,
        #ffdada 30%,
        #ffe0e0 70%
      );
      color: #295E59;
    font-style: italic;
    font-weight: bold;
    border-radius: 10px;

}
.operations{
    display:flex;
    padding-left: 10px;
}
button.sidebar-actions{
    display: flex;
    justify-content: center;
    align-items: center;

    width: 30px;
    height: 30px;
    color: #295E59;
    background-color: #F3CECE;
    /* box-shadow: 2px 0px 3px #a3a3a3; */
    border: none;
    border-radius: 20%;

    margin: 5px;
    padding: 5px;

    background-size: cover;
    position: fixed;
}
button.sidebar-actions:hover{
    transition-duration: 300ms;
    width: 40px;
    /* background-color: #e9d3d3; */
}
/* -----------------------Footer------------------------- */

footer{

    overflow: auto;
    height: 380px;

    margin-top: 50px;

}
.footer-top{
    overflow: auto;
    border-top: solid 2px #799b97;
    border-bottom: solid 2px #799b97;
    margin: 20px;
}
#mission-title{
    text-align: center;
}
blockquote{
    text-align: center;

    color: #255F5A;
    background-color: #DAEFED;

    font-style: italic;
   

    padding: 5px;
    font-size: 16px;
}

.footer-bottom{
    display: flex;
}

#linecircleBottom{
    margin: 10px;
    width: 20vw;
}
