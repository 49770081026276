body {
  min-height: 100vh;

  color: #3C3C3C;

  background-color: #FFF9F9;
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}
@media (prefers-reduced-motion: no-preference) {
  .logo-top:hover{
    animation: logo-top-move infinite 1s linear;
  }
}
@keyframes logo-top-move {
  25% {
    /* transform: translate(0px); */
    transform: rotate(10deg);
  }
  50% {
    /* transform: translate(50px); */
    transform: rotate(0deg);
  }
  75% {
    /* transform: translate(50px); */
    transform: rotate(-10deg);
  }
}
/* {
  border-bottom: 1px solid #4DAFA6;
  transition-duration: 300ms;
} */
p.tag{
  text-align: center;
  display: inline;

  font-size: 12px;

  border-radius: 20px;
  border: 1px solid #d1d1d1;
  color: #255F5A;
  background-color: #FFFFFF;

  margin: 5px;
  padding: 5px;
}
p.largetag{
  margin: 5px;
  padding: 5px;

  text-align: center;
  
  display: flex;
  align-items: center;

  border-radius: 20px;
  border: 1px solid #d1d1d1;
  color: #255F5A;
  background-color: #FFFFFF;

  font-size: 14px;
}
div.tags{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 30px;

}

textarea{
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

ul{
  padding: 0%;
  margin: 0%;
  list-style-type: none;
}

li{
  list-style: none;
}


a{
  color: #3C3C3C;
  text-decoration: none;
}
a:hover{
  text-shadow: 0px 2px 2px #b1b1b1;
}

hr.greenline{
background-color: #4DAFA6;
height: 0.222vh;
box-shadow: 0px 2px 2px #d1d1d1;
border: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.content-row{
  display: flex;

    padding: 20px;
}
.vector-team-row-vert{
  display:flex;
  flex-direction: column;
  padding: 20px;
}
